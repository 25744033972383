import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';

import { Section } from '../../components/common/';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
`;

const index1 = ({ data }) => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Grid>
        <Section
          lightBackground
          padding="1.5rem"
          style={{ position: 'sticky' }}
        >
          <h4>Base Website Docs</h4>
          <hr />
          <ul>
            <li>
              <Link to="/docs/">Overview</Link>
            </li>
            <li>
              <Link to="/docs/content">Content Management</Link>
            </li>
            <li>
              <Link to="/docs/search/">Site search</Link>
            </li>
            <li>
              <Link to="/docs/developers">Developers</Link>
            </li>
          </ul>
          <hr />
          <h4>Components</h4>
          <ul>
            <li>
              <Link to="/docs/components">What is a component?</Link>
            </li>
            {data.allComponentMetadata.edges.map(({ node: doc }) => (
              <li key={doc.id}>
                <Link to={`/docs/${doc.displayName}/`}>{doc.displayName}</Link>
              </li>
            ))}
          </ul>
        </Section>

        <Section>
          <h1>Base website documentation</h1>
          <h4 />
          <h2>Overview</h2>
          <p>
            The Base website is designed to be lightening fast using a modern
            development stack which uses technology in use by the like of
            Netflix, Facebook and Expedia. The result for user is a lighening
            fast experience with modern interactions.
          </p>
          <p>
            For content managers the website is managed by a headless CMS called
            Dato CMS. This simplifies content management comared to something
            like Joomla or Wordpress and reduces content management is simply
            filling out a form. THe display of the site is a seperate concern.
          </p>
          <p>
            From a developers point of view, the site uses modern development
            practices like repository management with git, continuous
            development and severless stack.
          </p>
        </Section>
      </Grid>
    </>
  );
};

export default index1;

export const pageQuery = graphql`
  query {
    allComponentMetadata {
      edges {
        node {
          id
          displayName
        }
      }
    }
  }
`;
